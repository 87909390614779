import {React, useState} from "react";
import styled from "styled-components";
import { MdAlternateEmail } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { HiOutlineMailOpen } from "react-icons/hi";
import { AiFillGithub, AiFillLinkedin, AiOutlineArrowUp } from "react-icons/ai";
import { BsFacebook, BsSlack } from "react-icons/bs";
import { FiMail, FiPhoneCall } from "react-icons/fi";
import { Slide, Zoom, Fade } from "react-awesome-reveal";
import { GoogleMap, InfoWindow, LoadScript, MarkerF } from "@react-google-maps/api"




import Header from "../Banner/Header";



const Footer = () => {
  const scrollUp = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  const initialMarkers = [
    {
        position: {
            lat: 40.992861,
            lng: 29.279167
        },
        label: { color: "black", text: "D" },
        draggable: true
    }
];

const [activeInfoWindow, setActiveInfoWindow] = useState("");
const [markers, setMarkers] = useState(initialMarkers);

const containerStyle = {
  width: "100%",
  height: "100%",
}

const center = {
  lat: 40.992861,
  lng: 29.279167,
}

const mapClicked = (event) => { 
  console.log(event.latLng.lat(), event.latLng.lng()) 
}

const markerClicked = (marker, index) => {  
  setActiveInfoWindow(index)
  console.log(marker, index) 
}

const markerDragEnd = (event, index) => { 
  console.log(event.latLng.lat())
  console.log(event.latLng.lng())
}

 

  
  
  return (
    <><Header /><Container id="footer">
      <Profile>
        <Slide direction="left" delay={1}>
          <h1 className="green">Bize Ulaşın</h1>
        </Slide>
        <br />
        <br />
        <h1>Bu sayfada iseniz profesyonel bir hizmet ile baskılı kolonyalı veya parfümlü mendil yaptırmaya karar vermişsiniz. Öyleyse;</h1>
        <br />

        <p>Kolonyalı veya parfümlü seçenekleri ile baskılı mendil sipariş vermek için markanıza özel tasarlayacağımız çalışmaları görebilirsiniz</p>
        <br />

        <div class="imgBox">
          <img src="ilet.jpeg" alt="abc" height="auto" width="40%" id="image-section" />
        </div>
        <br />
        <br />
        <br />
        <h2 className="green">Tasarım ve görsel çalışmanızı ÜCRETSİZ yapıyoruz</h2>
        <br />
        <p>Ekibimiz, göndereceğiniz logo ve baskı bilgilerini seçtiğiniz ürün için görselleri hazırlayarak, tarafınıza onay için dönüş yapacaktır.</p>
        <br />
        <br />
        <p>
          Teklif almak yada görüşlerinizi bildirmek için

          <a href = "mailto:info@docekagit.com" className="white"> info@docekagit.com</a> adresine mail
          atabilirsiniz.Ya da sorularınızı sormak için
          <a href="tel:+905323966703" className="white"> 0532 396 67 03</a> veya <a href="tel:+905333495686" className="white"> 0533 349 56 86 </a> numaralı telefonlardan bize ulaşabilirsiniz.
        </p>
        <div className="address">
          <Slide direction="left">
            <h2 className="green">Adres:</h2>
          </Slide>
          <Slide direction="left">
            <a href="https://goo.gl/maps/dxni2ntSPzpTm2ss8" className="white">Battalgazi Mah. Peyami SK. No:66/A, Sultanbeyli - Istanbul</a>
            <br />
        <br />
            <div style={{width: '20rem', height: '20rem'}}>
            
            <LoadScript googleMapsApiKey='AIzaSyDBFM6rKKp5fPlK8lhz7JELPX6gqMf_0LY'>
            <GoogleMap 
                mapContainerStyle={containerStyle} 
                center={center} 
                zoom={15}
                onClick={mapClicked}
            >
                {markers.map((marker, index) => (
                    <MarkerF 
                        key={index} 
                        position={marker.position}
                        label={marker.label}
                       
                    >
                        
                    </MarkerF>
                ))}
            </GoogleMap>
        </LoadScript>
     
        </div>
          </Slide>
        </div>
        <div className="links">
          <Slide direction="left">
            <h2 className="green">İletişim:</h2>
          </Slide>
          <br />
          <p>Baskılı Kolonyalı ve Parfümlü Mendil siparişlerinizi aşağıdaki iletişim bilgilerinden bize ulaştırabilirsiniz</p>
          <br />
          <h1>Coşkun Özel</h1>
          <div>
            <span>
              <FiPhoneCall />
            </span>
            <Slide direction="left">
              <a href="tel:+905323966703">0532 396 67 03</a>
            </Slide>
          </div>
          <div>
            <Slide direction="left">
              <span>
                <HiOutlineMailOpen />
              </span>
            </Slide>
            <Slide>
              <a href="mailto:coskun@docekagit.com">coskun@docekagit.com</a>
            </Slide>
          </div>
          <br />
          <h1>Oykan Özel</h1>
          <div>
            <span>
              <FiPhoneCall />
            </span>
            <Slide direction="left">
              <a href="tel:+905546557610">0554 655 76 10</a>
            </Slide>
          </div>
          <div>
            <Slide direction="left">
              <span>
                <HiOutlineMailOpen />
              </span>
            </Slide>
            <Slide>
              <a href="mailto:oykan@docekagit.com">oykan@docekagit.com</a>
            </Slide>
          </div>
          <br />
          <h1>Alkım Sezgin</h1>
          <div>
            <span>
              <FiPhoneCall />
            </span>
            <Slide direction="left">
              <a href="tel:+905302200687">0530 220 06 87</a>
            </Slide>
          </div>
          <div>
            <Slide direction="left">
              <span>
                <HiOutlineMailOpen />
              </span>
            </Slide>
            <Slide>
              <a href="mailto:info@docekagit.com">info@docekagit.com</a>
            </Slide>
          </div>
        </div>

        
      </Profile>

    </Container></>
  );
};

export default Footer;

const Container = styled.div`
  margin-top: 2rem;
  position: relative;
  padding: 2rem 0;
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @media (max-width: 840px) {
    width: 90%;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    gap: 3rem;
  }
  
`;
const Profile = styled.div`

@media screen and (max-width: 320px){

#image-section{
    width: 100%;
    height: auto;
  };

.imgBox{
    max-width: 400px;
    min-width: 200px;
  };
}

  flex: 1;
  .address {
    padding: 1rem 0;
    h1 {
      font-size: 1.2rem;
    }

    p {
      width: 60%;
      padding-top: 0.5rem;
      @media (max-width: 650px) {
        width: 100%;
      }
    }
  }

  .links {
    h1 {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      a {
        text-decoration: none;
        color: lightgray;
        :hover {
          color: orange;
        }
      }
    }
  }

  .profiles {
    h1 {
      font-size: 1.2rem;
      padding: 1rem 0;
    }

    .icons {
      display: flex;
      align-items: center;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
        border-radius: 50px;

        :hover {
          background-color: orange;
        }

        a {
          margin-top: 0.2rem;
          color: #fff;
        }
      }
    }
  }
`;
const ArrowUp = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: #01be96;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 700;
  margin-top: 2rem;
  @media (max-width: 650px) {
    position: absolute;
    right: 3rem;
    top: 16rem;
  }
`;
const Form = styled.div`
  flex: 1;
  h1 {
    font-size: 1.3rem;
    padding-bottom: 0.7rem;
  }

  form {
    background-color: #191923;
    padding: 0.8rem;
    border-radius: 5px;
    .name,
    .email,
    .message {
      display: flex;
      border: 1px solid gray;
      margin-bottom: 0.5rem;
      input,
      textarea {
        width: 100%;
        border: none;
        outline: none;
        color: #fff;
        background-color: transparent;
        padding: 1rem 0.5rem;
      }
      span {
        background-color: #3e3e3e;
        width: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .messageIcon {
        align-items: flex-start;
        padding-top: 0.5rem;
      }
    }

    button {
      width: 5rem;
      height: 1.8rem;
      background-color: #01be96;
      border: none;
      border-radius: 5px;
      filter: drop-shadow(0px 4px 5px #01be9551);
      cursor: pointer;
      :hover {
        filter: drop-shadow(0px 6px 9px #01be9551);
      }
    }
  }
`;
