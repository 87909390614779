import styled from "styled-components";
import Header from "./components/Banner/Header";
import ProfComponent from "./components/Banner/ProfComponent";
import Clients from "./components/Clients/Clients";
import Footer from "./components/Footer/Footer";
import Projects from "./components/Projects/Projects";
import Services from "./components/Service/Services";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import * as ROUTES from './constants/routes';


function App() {
  return (
   
    <BrowserRouter>
  
    <Routes>
            <Route exact path='/' element={<ProfComponent />}/>
            <Route exact path='/ürünler' element={<Projects />}/>
            <Route exact path='/sık_sorulanlar' element={<Clients />}/>
            <Route exact path='/iletişim' element={<Footer />}/>
          </Routes>
  </BrowserRouter>
   
  );
}

export default App;

const Container = styled.div``;
const Banner = styled.div`
  background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
 
  @media (max-width: 640px) {
    height: 100%;
    padding-bottom: 2rem;
  }
`;

const LightColor = styled.div`
  background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
`;
