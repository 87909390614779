import React, { useRef } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import ClientSlider from './ClientSlider';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Slide } from 'react-awesome-reveal';
import Header from "../Banner/Header";

let clients = [
    {
        soru : "Baskılı ıslak mendillerde minimum baskı adeti nedir?",
        cevap : `Baskılı ıslak mendil ürünlerimiz minimum 10.000 adet olarak üretime alınmaktadır. Bobin farklılıklarından dolayı adet de +- oynamalar olabilir çıkan adet üzerinden faturanız kesilir.`
    },
    {
        soru : "Baskılı ıslak mendillerde ekstradan alınan klişe bedeli nedir?",
        cevap : `Baskılı mendillerin üretiminde ihtiyaç duyulan klişe (kalıp) her baskıda yenilenmediği için ücreti sadece ilk siparişe yansıtılır. Sonraki siparişleriniz de tasarım değiştirmediğiniz sürece bu ücret talep edilmez.`
    },
    {
        soru : "70° Alkollü veya parfümlü mendil üzerine istediğimiz her türlü baskı yapılabilir mi?",
        cevap : `Baskılı mendillerin ufak bir reklam alanı olduğunu düşünürsek, ufak detaylarda baskıda kayıp yaşanması için net ve sade tasarımlar tercih edilir. Böyle ufak ama etkili bir reklam aracında karmaşaya gerek yoktur. Baskılı Islak mendil üzerine fotoğraf baskısı yapılabilir.`
    },
    {
        soru : "Baskılar ekran görüntüsündeki onay görselinden daha farklı tonlarda teslim edildi neden?",
        cevap : `Bilgisayar veya telefon ekranında gördüğünüz renge göre baskı farklılık gösterebilir. Ekranların birbirinden farklı kontrast ayarından kaynaklı renk farkının olması dijital ortamla alakalıdır. Sizin ekranınızda gördüğünüz renkler başka bir ekranda açıldığında daha farklı gözükebilir.`
    },
    {
        soru : "Verilen her ayrı siparişte baskıların renk tonlarında ki farkın sebebi nedir?",
        cevap : `UV Flexo baskı tekniği uygulanarak üretimi yapılan baskılı mendiller 4 ana renkten oluşan karışımlar sonucu elde edilir. Boyadan kaynaklı olarak % 20-25 ton farkı normal olarak karşılanmaktadır ve hata sebebi değildir.
        `
    },
    {
        soru : "Sipariş vermem durumunda ürünlerim ne zaman teslim edilir?",
        cevap : `Grafik ekibimiz tarafından yapılan tasarımlar onayınıza sunulur. Onay sonrası üretim süreci 10 iş günüdür. Vereceğiniz siparişler için lütfen bu süreci düşünerek tüketim planı yapınız.
        `
    },
]
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows : false,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 530,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]}

const Clients = () => {
    const arrowRef = useRef(null);
    let clientDisc = "";
    clientDisc = clients.map((item, i) => (
        <ClientSlider item={item} key={i}/>
    ))
  return (
      <><Header /><Container id='client'>
          <Slide direction="left">
              <span className="green">Sık Sorulanlar</span>
              <h1>Baskılı Islak Mendil üretimi ve teslimat ile ilgili sorularınızı cevaplamak gerekirse</h1>
          </Slide>
          <Testimonials>    
                  {clientDisc}
          </Testimonials>
      </Container></>
  )
}

export default Clients

const Container = styled.div`
    width: 80%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 4rem 0;

    @media(max-width:840px){
        width: 90%;
    }

    span{
        font-weight: 700;
        text-transform: uppercase;
    }

    h1{
        padding-top: 1rem;
        
    }

    .slick-list, .slick-slider, .slick-track{
        padding: 0;
    }

    .slick-dots{
        text-align: left;
        margin-left: 1rem;
    }

    .slick-dots li button:before{
        content: "";
    }

    .slick-dots li button{
        width: 9px;
        height: 4px;
        background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
        padding: 0.1rem;
        margin-top: 1rem;
        transition: all 400ms ease-in-out;
        border-radius: 50px;
    }
    
    .slick-dots li.slick-active button{
        background: #01be96;
        width: 15px;
    }

    .slick-dots li{
        margin: 0;
    }
`

const Testimonials = styled.div`
    margin-top: 2rem;
    position: relative;
`
const Buttons = styled.div`
    position: absolute;
    right: 0.7rem;
    bottom: -2rem;

    button{
        background-color: transparent;
        margin-left: 0.5rem;
        border: none;
        color: #01be96;
        cursor: pointer;
        font-size: 1.1rem;
    }

    @media(max-width:530px){
        display: none;
    }
`