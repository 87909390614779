import React from "react";
import styled from "styled-components";
import SliderComp from "./Slider";
import { Zoom } from "react-awesome-reveal";
import Header from "../Banner/Header";

const Projects = () => {
  

  return (
    <><Header /><Container id="project">
      <Zoom>
        <h1>
          <span className="green">Ürünlerimiz</span>
        </h1>
      </Zoom>
      <Slide>
        <SliderComp />
      </Slide>
      <br />
      <h3>
        Markanıza özel olarak üretilen Baskılı Islak Mendil ölçüleri aşağıdaki
        gibidir
      </h3>
      <br />
      <p className="pr green">
        {" "}
        5x10cm  6x10cm  7x10cm  8×10cm  9×10c  10×10cm  5x12cm  6×12cm  7×12cm
        8×12cm  9×12cm  7×14cm  8×14cm  9×14cm  10×12cm  10×14cm
      </p>
      <p className="pr">
      Tripleks,dublex veya kuşe  ambalaj kalitesi ile ürünleriniz uzun süre kuruma yapmaz. 70° Alkollü ve parfüm içerikli seçeneklerimiz mevcuttur
      </p>
      <p>
      Cafe/bar, restoran, otel gibi yiyecek içecek sektörüne hizmet veren işletmelerin vazgeçilmezi haline gelen baskılı ıslak mendil ürünleri “kolonyalı” ve “parfümlü” olmak üzere isteğinize göre markanıza özel üretim yapılır.
      </p>
      <br />  <br />




    </Container></>
  );
};

export default Projects;

const Container = styled.div`
  width: 55%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  text-align: center;
  position: relative;
  @media (max-width: 840px) {
    width: 90%;
  }
  h1 {
    font-size: 1.9rem;
  }

  .pr {
    white-space: pre-wrap;
    width: 28rem;
    margin: 0 auto;
    padding: 1rem 0;
    font-size: 0.9rem;
    @media (max-width: 500px) {
      width: 90%;
    }
  }
`;

const Slide = styled.div``;
