import React from "react";
import styled from "styled-components";
import { AiOutlineInstagram } from "react-icons/ai";
import { GiEarthAmerica } from "react-icons/gi";
import { FaLinkedinIn } from "react-icons/fa";
import { Slide } from "react-awesome-reveal";
import Header from "./Header";

const ProfComponent = () => {
  return (
    <div><Header /><Container id="home">
      <Slide direction="left">
        <Texts>
          <h3>
            Her türlü işletmenin ihtiyacına karşılık verecek baskılı ıslak
            mendiller kolonyalı parfümlü seçenekleri ile üretim yapılmaktadır
          </h3>
          <p>
            Logonuzu bulunduran baskılı ıslak mendil ,müşterilerinizin temizlik
            ihtiyacını karşılarken, markanız için reklam ve hizmet ürünü
            olacaktır. Etkili bir tasarım ve kaliteli üretim ile markanızın
            akıllarda kalmasını sağlar.
          </p>
          <br />
          <p>
            Mendilleriniz ,bize ulaştıracağınız firma logonuz ve bilgileriniz
            kullanılarak ekibimiz tarafından tasarlanır ve onayınıza sunulur.
          </p>
          <br />
          <h3 className="red">
            “Standart baskılı üretilmiş, stokta bulunan baskılı ıslak mendil ve diğer ihtiyaçlarınızın siparişlerini aynı gün teslim alabilir, üretim sürecini beklemeden ihtiyacınızı karşılayabilirsiniz.”
          </h3>
          <br />
          <p>
            Ekibimiz ile birlikte baskılı ıslak mendil ve diğer ihtiyaçlarınız
            için biz buradayız.
          </p>
          <br />

          <p>
            Teklif almak yada görüşlerinizi bildirmek için
            <a href = "mailto:info@docekagit.com" className="white"> info@docekagit.com</a> adresine mail
            atabilirsiniz.Ya da sorularınızı sormak için
            <a href="tel:+905323966703" className="white"> 0532 396 67 03</a> veya  <a href="tel:+905333495686" className="white"> 0533 349 56 86 </a>
            numaralı telefonlardan bize ulaşabilirsiniz.
          </p>
          <br />
          <br />

          <h1 className="red">
            <span className="red">İşletmeniz için önerilemiz var</span>
          </h1>


          <br />
          <li>Size özel logo tasarımlı muhtelif ebatlarda baskılı mendil</li>
          <li>Baskısız veya baskılı muhtelif ebatlarda hazır ıslak mendil</li>
          <li>Markanıza özel baskılı muhtelif ebatlarda şeker,tuz, baharatlar</li>
          <li>Markanıza özel baskılı muhtelif ebatlarda peçeteler</li>
          <li>Markanıza özel baskılı muhtelif ebatlarda servis ürünleri</li>

          <a href="/iletişim">
            <button>Bize ulaşın</button>
          </a>


          <br />  <br />  <br />  <br />  <br />

        </Texts>
      </Slide>
      <Slide direction="right">
        <Profile>
          <img src="do.png" alt="logo" />
        </Profile>
      </Slide>
    </Container>
    </div>
  );
};

export default ProfComponent;

const Container = styled.div`
  display: flex;
  gap: 2rem;
  padding-top: 3rem;
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  z-index: 1;
  @media (max-width: 840px) {
    width: 90%;
  }

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;
const Texts = styled.div`
  flex: 1;
  h4 {
    padding: 1rem 0;
    font-weight: 500;
  }
  h1 {
    font-size: 2rem;
    font-family: "Secular One", sans-serif;
    letter-spacing: 2px;
  }
  h3 {
    font-weight: 500;
    font-size: 1.2rem;
    padding-bottom: 1.2rem;
  }
  p {
    font-weight: 300;
  }

  button {
    padding: 0.7rem 2rem;
    margin-top: 3rem;
    cursor: pointer;
    background-color: #01be96;
    border: none;
    color: #fff;
    font-weight: 500;
    filter: drop-shadow(0px 10px 10px #01be9551);
    :hover {
      filter: drop-shadow(0px 10px 10px #01be9570);
    }
  }
  .rowr {
    flexdirection: "row";
  }
`;
const Social = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  p {
    font-size: 0.9rem;
    @media (max-width: 690px) {
      font-size: 0.7rem;
    }
  }

  .social-icons {
    display: flex;
    align-items: center;
    gap: 1rem;
    span {
      width: 2.3rem;
      height: 2rem;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
      background-color: #01be96;
      position: relative;
      transition: transform 400ms ease-in-out;
      :hover {
        transform: rotate(360deg);
      }
    }

    a {
      color: #fff;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
const Profile = styled.div`
  img {
    width: 23rem;
    filter: drop-shadow(0px 10px 10px #01be9570);
    transition: transform 400ms ease-in-out;
    @media (max-width: 790px) {
      width: 18rem;
    }

    @media (max-width: 660px) {
      width: 18rem;
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  :hover img {
    transform: translateY(-10px);
  }
`;
