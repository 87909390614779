import React from "react";
import { BsSpeedometer2 } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import { BiSupport } from "react-icons/bi";
import styled from "styled-components";
import Card from "./Card";
import { Slide } from "react-awesome-reveal";

const Services = () => {
  return (
    <Container id="service">
      <Slide direction="down">
        <h4>
          <span className="green">İşletmeniz için önerilemiz var
</span>
        </h4>
        <h1>En İyi Deneyimin Keyfini Çıkarın</h1>
      </Slide>
      <Cards>
        <Slide direction="left">
          <Card
            Icon={BsSpeedometer2}
            title={"Hızlı Teslimat"}
            disc={`Açıklama 1`}
          />
        </Slide>
        <Slide direction="up">
          <Card
            Icon={TiTick}
            title={"Yüksek Kalite"}
            disc={`Açıklama 2`}
          />
        </Slide>
        <Slide direction="right">
          <Card
            Icon={BiSupport}
            title={"Kesintisiz Destek"}
            disc={`Açıklama 3`}
          />
        </Slide>
      </Cards>
      <br/> <br/>
      <h3>Hakkımızda yazısı</h3>
    </Container>
  );
};

export default Services;

const Container = styled.div`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  @media (max-width: 840px) {
    width: 90%;
  }

  h1 {
    padding-top: 1rem;
  }
`;
const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 4rem;
  gap: 1rem;
`;
